import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 237.000000 248.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,248.000000) scale(0.100000,-0.100000)">

<path d="M1348 2413 c-36 -43 -57 -92 -63 -149 l-7 -61 -127 -6 c-69 -3 -155
-13 -191 -22 -83 -21 -257 -105 -298 -143 l-32 -30 -79 11 c-89 12 -168 0
-209 -31 -29 -22 -78 -137 -55 -128 8 3 16 6 18 6 2 0 11 22 19 48 22 64 58
82 164 82 42 0 82 -4 88 -8 8 -4 -10 -31 -50 -74 l-63 -67 -100 0 c-55 0 -111
-5 -124 -10 -18 -7 5 -10 83 -10 59 -1 109 -5 112 -9 3 -5 -12 -43 -33 -86
-56 -110 -83 -220 -89 -354 -9 -215 53 -407 186 -573 87 -109 82 -108 125 -39
l36 59 -29 -4 c-17 -2 -30 -2 -30 0 0 2 20 16 44 30 24 14 50 32 56 40 7 8 16
13 20 10 13 -8 81 28 77 39 -27 70 -39 128 -30 137 19 19 60 -18 77 -68 21
-60 21 -60 -13 -78 -40 -20 -63 -45 -57 -61 7 -19 -8 -18 -24 1 -7 8 -16 15
-20 15 -4 0 -34 -39 -65 -87 -32 -49 -75 -114 -97 -145 l-39 -57 28 -17 c15
-10 33 -22 39 -26 8 -6 21 7 38 37 15 25 30 45 33 45 3 0 25 -11 48 -25 41
-23 43 -26 56 -101 13 -73 15 -77 57 -106 36 -25 46 -28 53 -17 5 8 24 38 41
67 32 51 34 53 68 47 19 -3 43 -8 52 -11 13 -4 18 0 18 15 0 26 44 56 67 46
15 -6 12 -9 -14 -17 -38 -10 -42 -20 -46 -137 l-3 -83 111 4 c92 3 117 7 150
26 50 29 85 78 92 130 4 31 10 41 28 44 18 4 38 -12 97 -77 l75 -82 39 32 38
32 -61 68 c-34 38 -60 72 -57 77 3 4 36 8 75 8 l69 0 0 38 c0 27 7 45 23 60
l24 22 50 -53 50 -53 39 33 38 33 -53 54 -54 54 41 49 c217 259 242 705 56
983 -21 30 -38 66 -38 80 -1 20 -6 24 -24 22 -39 -6 -29 42 17 79 41 32 68 80
56 98 -4 8 -12 8 -26 1 -29 -16 -35 12 -11 52 35 59 35 112 0 113 -7 1 -29 5
-49 9 l-36 8 5 47 c4 46 -17 111 -37 111 -14 0 -48 -43 -87 -110 -95 -163
-223 -247 -554 -360 -69 -24 -148 -53 -176 -66 -58 -26 -114 -70 -114 -90 0
-24 -17 -15 -23 12 -3 20 0 28 14 31 11 3 19 9 19 13 0 4 20 18 45 31 36 19
55 41 101 115 40 66 64 94 80 97 13 2 26 2 29 -1 3 -3 5 -33 5 -67 l0 -62 71
25 c53 19 76 33 89 55 16 26 19 27 28 11 12 -21 24 -17 140 48 48 28 68 44 60
50 -9 5 -5 8 10 8 24 0 72 27 72 41 0 4 -19 1 -42 -7 -39 -14 -44 -13 -55 3
-7 9 -13 35 -13 58 0 71 -71 185 -115 185 -6 0 -19 -8 -27 -17z m446 -88 c22
-33 20 -54 -5 -81 l-21 -23 56 6 c52 5 58 4 63 -15 3 -12 9 -19 14 -16 31 19
-14 -79 -49 -106 -14 -12 -19 -20 -11 -20 22 -1 62 -39 56 -54 -11 -29 -73
-105 -86 -105 -10 -1 -11 2 -2 13 9 11 1 23 -39 59 -28 25 -71 58 -95 74 -65
41 -63 73 11 184 84 126 82 124 108 84z m-370 -19 c9 -19 16 -57 16 -86 l0
-52 -32 7 c-61 12 -70 16 -64 32 3 8 6 32 6 54 0 26 7 46 20 59 27 27 35 25
54 -14z m-106 -136 c37 -6 85 -17 106 -24 l39 -13 -24 -17 c-22 -14 -34 -14
-119 -2 -129 19 -324 2 -375 -33 -5 -4 -37 -18 -70 -31 -33 -13 -78 -35 -100
-50 -22 -15 -47 -29 -55 -32 -13 -5 -12 -7 2 -13 11 -4 28 1 47 14 26 19 134
71 146 71 6 0 -79 -145 -87 -148 -5 -2 -5 -9 -2 -15 3 -7 -6 3 -21 21 -14 17
-33 32 -41 32 -22 0 -114 49 -114 60 0 13 73 66 135 97 157 81 352 111 533 83z
m-58 -100 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m356 -112 c35 -23 64 -45 64 -48 0 -3 -28 -21 -62 -39 -48 -27 -63
-31 -66 -20 -3 8 -25 51 -49 97 -23 46 -43 85 -43 87 0 10 98 -39 156 -77z
m82 45 c14 -21 17 -34 11 -51 -9 -25 -21 -22 -67 17 -19 17 -21 24 -12 41 15
28 45 25 68 -7z m-295 -103 c27 -49 46 -94 43 -99 -8 -12 -134 -41 -175 -41
l-31 0 0 79 c0 44 3 87 6 96 6 15 74 53 99 54 6 1 32 -39 58 -89z m-755 54
c12 -8 22 -19 22 -23 0 -5 -28 -23 -62 -41 -58 -31 -119 -81 -129 -105 -2 -5
-15 -28 -29 -50 -14 -22 -25 -42 -26 -45 0 -3 -9 -23 -19 -45 -14 -31 -18 -58
-15 -120 7 -173 9 -160 -25 -160 l-30 0 3 60 c8 167 101 369 231 503 46 47 48
48 79 26z m77 -54 c10 -11 14 -20 9 -20 -12 1 -44 25 -44 34 0 12 17 6 35 -14z
m405 -64 c0 -41 -3 -77 -7 -79 -11 -6 -184 30 -195 41 -11 11 52 54 129 86 32
13 61 25 66 25 4 1 7 -32 7 -73z m57 33 c7 -13 13 -45 13 -71 0 -66 -24 -89
-85 -83 -25 2 -53 9 -63 15 -13 8 -3 9 41 5 l57 -6 0 86 c0 91 11 106 37 54z
m332 -3 c10 -22 11 -34 2 -53 -6 -13 -16 -21 -21 -18 -6 3 -10 0 -10 -7 0 -7
-11 11 -25 40 -14 30 -25 55 -25 57 0 5 43 13 55 10 6 -1 16 -14 24 -29z m341
-42 c0 -20 -97 -114 -118 -114 -14 -1 -14 -4 4 -19 29 -23 16 -46 -34 -61 -20
-6 -47 -16 -59 -22 -12 -6 -30 -7 -40 -3 -17 8 -17 8 0 12 21 5 21 8 1 67 -9
26 -13 51 -8 55 5 4 43 26 86 49 l76 41 16 -24 c21 -32 31 -31 53 5 20 34 23
36 23 14z m-1097 -84 c5 -4 -3 -43 -16 -86 -14 -44 -30 -115 -37 -159 -7 -44
-14 -86 -17 -94 -9 -33 -27 14 -54 138 -31 144 -62 227 -80 215 -13 -8 -8 3
23 48 l19 27 77 -41 c42 -23 81 -44 85 -48z m42 72 c-3 -3 -11 0 -18 7 -9 10
-8 11 6 5 10 -3 15 -9 12 -12z m1053 -118 c30 -52 68 -175 77 -247 l7 -58
-133 3 -134 3 -7 59 c-6 47 -4 63 7 76 17 19 20 46 5 55 -19 12 -10 23 25 29
19 4 48 18 64 32 26 22 29 28 18 44 -9 16 -8 22 8 34 27 21 36 16 63 -30z
m-1304 -21 c21 -67 32 -138 40 -258 1 -18 -6 -20 -81 -23 l-83 -3 0 29 c0 68
78 302 101 302 4 0 14 -21 23 -47z m534 -30 c42 -6 42 -6 42 -48 l0 -42 53
-15 52 -15 3 57 3 57 42 6 c23 3 79 17 125 30 l83 24 9 -23 c5 -13 17 -49 26
-81 9 -32 18 -60 20 -62 1 -2 23 8 48 22 34 19 46 22 46 12 0 -64 -280 -92
-447 -45 -43 12 -86 26 -95 31 -24 12 -23 6 8 -46 17 -29 24 -55 22 -77 l-3
-33 -159 -3 -158 -3 6 58 c7 57 35 173 55 224 l10 26 83 -24 c46 -13 102 -27
126 -30z m-314 -199 c-4 -36 -13 -72 -21 -80 -17 -16 -159 -20 -183 -4 -10 7
9 10 62 10 87 0 81 -7 93 98 3 29 11 65 17 80 l10 27 14 -33 c10 -24 13 -52 8
-98z m1197 23 c4 -63 -7 -69 -16 -10 -3 26 -9 59 -12 73 -5 25 -5 25 10 4 9
-11 17 -42 18 -67z m-787 17 c11 -28 6 -38 -9 -18 -8 10 -15 22 -15 27 0 13
18 7 24 -9z m375 -67 l1 -28 -157 3 -158 3 -3 42 -3 41 146 0 c81 0 149 3 153
7 8 8 20 -29 21 -68z m55 51 c3 -13 7 -35 10 -49 3 -15 -1 -34 -10 -47 -14
-20 -24 -22 -107 -22 -51 0 -99 5 -107 10 -10 7 16 10 84 10 l99 0 -6 60 c-6
55 -5 60 13 60 12 0 21 -8 24 -22z m-434 -99 c-9 -17 -22 -19 -103 -19 -51 0
-99 5 -107 10 -10 7 16 10 83 10 l97 0 0 33 1 32 19 -23 c15 -18 17 -28 10
-43z m770 -9 c-8 -5 -42 -10 -75 -10 -33 0 -67 5 -75 10 -10 7 13 10 75 10 62
0 85 -3 75 -10z m-1268 -97 c8 -10 20 -50 27 -90 6 -40 23 -109 37 -153 14
-45 24 -83 22 -84 -2 -2 -39 -22 -83 -45 l-80 -42 -28 33 c-44 51 -116 206
-132 283 -26 123 -33 115 103 115 101 0 121 -3 134 -17z m448 -103 c0 -73 -4
-120 -10 -120 -6 0 -10 29 -10 68 -1 74 -19 68 -22 -7 -1 -23 -5 -41 -9 -41
-5 0 -6 -6 -4 -13 4 -8 -21 -19 -77 -34 -46 -12 -93 -25 -104 -28 -19 -6 -23
1 -44 77 -12 46 -25 102 -28 126 l-5 42 34 0 c19 0 52 7 74 15 l40 14 -74 -2
c-52 -1 -75 2 -78 11 -4 9 32 12 156 12 l161 0 0 -120z m195 79 c36 -36 166
-131 194 -140 7 -3 4 -25 -8 -66 -23 -76 -10 -95 14 -21 31 94 56 95 31 1 -13
-53 -13 -59 2 -70 27 -21 38 -15 51 29 7 23 17 52 21 64 l9 22 70 -21 c59 -18
79 -19 121 -11 33 7 50 7 50 1 0 -6 11 0 25 13 14 13 25 19 25 13 0 -18 -66
-70 -106 -83 -23 -8 -63 -10 -106 -7 -67 6 -68 5 -68 -18 0 -35 -35 -41 -98
-16 l-53 21 -34 -34 c-20 -20 -42 -33 -52 -31 -10 2 -23 4 -29 4 -5 1 -19 7
-30 15 -17 13 -16 14 18 7 25 -6 43 -5 54 3 15 11 13 14 -13 25 -33 14 -62 51
-39 51 7 0 32 -7 55 -15 40 -14 58 -4 24 13 -20 10 -121 37 -170 46 -27 5 -33
2 -33 -13 0 -19 -24 -44 -50 -53 -18 -6 -52 5 -50 16 1 5 -4 13 -12 18 -10 7
-10 11 0 15 8 3 12 27 12 71 l0 66 22 -21 c27 -27 32 -118 8 -133 -12 -8 -11
-10 7 -10 42 0 53 34 53 167 0 122 0 123 23 123 14 0 39 -16 62 -41z m231 -1
c-4 -24 -9 -56 -12 -73 -5 -29 -3 -31 46 -48 29 -10 53 -17 55 -15 7 7 25 112
25 143 l0 35 135 0 c155 0 144 9 120 -105 -8 -38 -15 -72 -15 -75 0 -2 20 16
45 40 46 45 59 45 28 0 -38 -55 -113 -90 -192 -90 -60 0 -259 60 -285 86 -8 8
-19 14 -24 14 -5 0 -17 12 -27 26 -10 15 -22 23 -31 20 -10 -4 -12 -2 -8 6 5
7 2 9 -9 5 -9 -3 -21 1 -29 11 -12 15 -11 16 7 3 11 -7 2 3 -20 23 l-40 36
119 0 119 0 -7 -42z m-463 -243 c7 -18 2 -21 -48 -29 -39 -6 -76 6 -63 20 8 7
63 21 89 23 9 1 19 -6 22 -14z m771 -65 c-15 -22 -29 -40 -31 -40 -5 0 3 15
29 53 30 43 31 32 2 -13z m-146 11 c24 -5 59 -5 78 -1 19 4 34 5 34 0 0 -12
-60 -90 -69 -90 -5 0 -19 12 -31 27 l-22 28 -39 -42 c-23 -25 -41 -37 -45 -31
-4 6 7 21 24 34 27 21 30 28 22 54 -5 17 -6 30 -2 30 4 -1 26 -5 50 -9z m-588
-147 c0 -81 4 -123 10 -119 6 3 10 22 10 41 0 19 4 34 8 34 12 0 64 -56 62
-66 -1 -5 3 -10 9 -12 8 -3 11 37 11 122 l0 126 29 0 c17 0 44 -13 67 -31 21
-17 51 -33 66 -36 16 -3 28 -10 28 -16 0 -11 -74 -152 -95 -180 -11 -15 -17
-16 -34 -8 -11 6 -67 11 -125 11 l-106 0 -1 -37 c-1 -34 -2 -35 -9 -13 -4 14
-6 34 -4 45 3 14 -5 26 -29 41 l-33 21 -54 -81 c-30 -44 -55 -83 -57 -85 -2
-2 -6 -2 -9 2 -3 3 8 26 26 52 17 25 29 53 26 60 -3 8 -1 14 4 12 5 -1 20 11
34 28 l24 30 -34 67 c-19 37 -34 70 -34 72 0 11 128 42 183 45 l27 1 0 -126z
m-540 33 c0 -13 -39 12 -50 33 -20 38 -9 43 20 10 16 -19 29 -39 30 -43z m316
-184 c-1 -1 -16 3 -33 9 -34 13 -44 38 -58 143 -7 50 -7 50 44 -50 27 -54 49
-100 47 -102z m716 167 c10 0 12 -17 8 -72 l-5 -73 -87 0 c-82 0 -88 -2 -117
-29 -28 -27 -33 -28 -55 -17 l-24 13 40 67 c22 36 42 67 43 69 2 2 13 -2 25
-8 18 -10 21 -18 17 -46 -10 -58 12 -38 51 45 l37 79 27 -14 c15 -8 33 -14 40
-14z m-302 -359 c-16 -31 -66 -52 -113 -49 -39 3 -42 5 -45 34 l-3 31 83 9
c46 5 84 6 86 2 2 -4 -2 -16 -8 -27z"/>
<path d="M1584 695 c-18 -14 -18 -14 9 -15 17 0 27 5 27 15 0 19 -11 19 -36 0z"/>
<path d="M446 1941 c-13 -5 -16 -10 -8 -15 14 -8 62 3 62 15 0 11 -27 11 -54
0z"/>
<path d="M937 221 c4 -15 -1 -20 -19 -23 -19 -2 -23 -9 -23 -38 0 -35 0 -35
50 -38 l50 -3 -3 61 c-4 58 -5 60 -32 60 -23 0 -27 -4 -23 -19z m0 -78 c-3
-10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M1618 185 c3 -35 0 -55 -7 -55 -6 0 -11 16 -11 35 l0 35 -53 0 -54 0
6 -37 6 -38 105 0 105 0 0 35 c0 28 -4 36 -21 38 -18 3 -20 -1 -16 -34 3 -27
1 -35 -7 -29 -7 4 -11 17 -10 28 4 60 -1 77 -23 77 -22 0 -23 -4 -20 -55z
m-58 -19 c0 -14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0 10
-11 10 -24z"/>
<path d="M230 160 c11 -22 25 -40 31 -40 16 0 21 27 9 49 -6 12 -7 21 -2 21 4
0 16 -16 25 -36 10 -19 23 -34 30 -31 11 4 11 14 5 65 -1 8 -21 12 -60 12
l-58 0 20 -40z"/>
<path d="M345 190 c3 -5 11 -7 18 -4 8 3 20 -10 30 -31 9 -19 21 -35 27 -35
17 0 22 27 10 49 -6 12 -7 21 -2 21 4 0 16 -16 25 -36 10 -19 23 -34 30 -31
11 4 11 14 5 65 -1 8 -24 12 -75 12 -45 0 -72 -4 -68 -10z"/>
<path d="M505 190 c3 -5 11 -7 18 -4 8 3 20 -10 30 -31 9 -19 21 -35 27 -35
17 0 22 27 10 49 -6 12 -7 21 -2 21 4 0 16 -16 25 -36 10 -19 23 -34 30 -31
11 4 11 14 5 65 -1 8 -24 12 -75 12 -45 0 -72 -4 -68 -10z"/>
<path d="M665 190 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M755 160 l0 -40 65 1 c36 0 59 3 53 6 -7 2 -13 20 -13 39 0 27 -4 34
-19 34 -15 0 -21 -8 -24 -37 l-4 -38 -1 38 c-2 33 -5 37 -29 37 -26 0 -28 -3
-28 -40z"/>
<path d="M1017 182 c-10 -10 -17 -21 -17 -25 0 -14 34 -37 55 -37 23 0 55 23
55 40 0 17 -32 40 -54 40 -13 0 -30 -8 -39 -18z m40 -39 c-3 -10 -5 -2 -5 17
0 19 2 27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M1115 160 c0 -36 2 -40 25 -40 22 0 25 4 24 37 -1 32 -5 38 -25 41
-22 3 -24 -1 -24 -38z"/>
<path d="M1180 161 l0 -41 53 0 54 0 -6 38 c-5 27 -12 38 -27 40 -18 3 -20 -2
-16 -33 2 -20 0 -35 -6 -35 -6 0 -12 15 -14 32 -2 22 -9 34 -20 36 -15 3 -18
-4 -18 -37z"/>
<path d="M1305 191 c-3 -5 0 -13 7 -17 6 -4 14 -2 16 4 5 17 22 15 22 -2 0 -9
-12 -16 -27 -18 -34 -4 -38 -32 -6 -36 17 -3 21 0 17 12 -3 9 -2 16 4 16 5 0
12 -7 16 -15 3 -8 14 -15 24 -15 15 0 18 6 14 33 -3 17 -10 35 -16 39 -16 11
-64 10 -71 -1z"/>
<path d="M1413 184 c-4 -11 2 -23 17 -34 17 -13 20 -19 9 -23 -9 -3 -6 -6 7
-6 12 -1 27 5 33 13 10 12 7 18 -14 32 -20 13 -23 19 -13 25 9 6 6 9 -10 9
-12 0 -25 -7 -29 -16z"/>
<path d="M1812 188 c-29 -29 -8 -69 36 -67 11 0 12 2 5 6 -7 2 -13 13 -13 24
0 13 5 17 16 12 12 -4 15 -1 12 13 -4 22 -38 30 -56 12z"/>
<path d="M1897 182 c-10 -10 -17 -21 -17 -25 0 -14 34 -37 55 -37 23 0 55 23
55 40 0 17 -32 40 -54 40 -13 0 -30 -8 -39 -18z m40 -39 c-3 -10 -5 -2 -5 17
0 19 2 27 5 18 2 -10 2 -26 0 -35z"/>
<path d="M1995 160 c0 -36 2 -40 25 -40 22 0 25 4 24 37 -1 32 -5 38 -25 41
-22 3 -24 -1 -24 -38z"/>
<path d="M2060 161 l0 -41 53 0 54 0 -6 38 c-5 27 -12 38 -27 40 -18 3 -20 -2
-16 -33 2 -20 0 -35 -6 -35 -6 0 -12 15 -14 32 -2 22 -9 34 -20 36 -15 3 -18
-4 -18 -37z"/>
<path d="M702 138 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
<path d="M1747 144 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
